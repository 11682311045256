/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;1,500;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800&family=Roboto:ital,wght@0,300;0,500;1,500;1,900&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
  background: #121312;
  background-image: url(../src/assets/bg-texture.png);
}

body {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  width: 100%;
  color: #ffffff;
}

#root {
  overflow-x: hidden;
}

img {
  max-height: 70%;
}

h5, h4 {
  font-size: 1.4rem;
}

h1 {
  font-size: 3rem;
}

#home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-block: 3rem;
}
@media only screen and (max-width: 56.25em) {
  #home {
    display: flex;
    flex-direction: column;
  }
}

.nav__bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 30%;
  max-width: 50%;
  height: 100%;
}
@media only screen and (max-width: 56.25em) {
  .nav__bar {
    min-width: 100%;
  }
}
@media only screen and (max-width: 37.5em) {
  .nav__bar {
    display: none;
  }
}
.nav__bar__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.nav__bar__list__item {
  list-style: none;
}
.nav__link {
  position: relative;
  color: rgba(255, 255, 255, 0.61);
  font-size: 2rem;
  text-decoration: none;
  padding-block: 1rem;
  margin-left: 1.5rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  padding: 0 1rem;
}
@media only screen and (max-width: 75em) {
  .nav__link {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .nav__link {
    font-size: 1.6rem;
  }
}
.nav__link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0.3rem;
  left: -0.2rem;
  bottom: -0.5rem;
  background-color: #2ee28e;
  transition: 0.5s;
}
.nav__link:hover::before {
  width: 100%;
}
.nav__link:hover {
  color: #ffffff;
}

.logo-container {
  width: 12rem;
  height: 80%;
}
@media only screen and (max-width: 75em) {
  .logo-container {
    width: 10rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .logo-container {
    padding-block: 3rem;
    width: 10rem;
  }
}

.logo-container img {
  width: 100%;
  min-height: 100%;
}

.navigation__active {
  font-size: 2.2rem;
  color: #2ee28e;
  border-radius: 50%;
  padding: 0.7rem 0.7rem 0 0.7rem;
  background-color: rgba(36, 36, 36, 0.877);
}
.navigation__mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  max-width: 40rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 100;
  height: 6rem;
  background-color: transparent;
}
.navigation__mobile__list {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  border: 2px solid #2ee28e;
  border-radius: 10rem;
  background-color: #121312;
  margin-bottom: 2rem;
  min-height: 6rem;
}
.navigation__mobile__link {
  color: #ffffff;
  font-size: 2.2rem;
  text-decoration: none;
  transition: all 0.2s;
  border-radius: 50%;
  padding: 0.6rem 0.6rem 0 0.6rem;
}
.navigation__mobile__link:hover {
  padding: 0.7rem 0.7rem 0 0.7rem;
  background-color: rgba(36, 36, 36, 0.877);
}
.navigation__mobile__link svg:hover {
  color: #2ee28e;
  transform: scale(1.1);
}

.btn {
  cursor: pointer;
  padding: 1rem;
  background-color: #121312;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: 700;
  color: #2ee28e;
  border-radius: 1rem;
  border: 1px solid #2ee28e;
  transition: 0.5s;
}
.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 1rem #2ee28e, 0 0 1.5rem #73d6a8;
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.btn__primary {
  background-color: #2ee28e;
  color: #121312;
  border: none;
}
.btn__icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
}
.btn__text {
  padding-block: 1rem;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.3rem;
}
.btn__text:hover {
  transform: scale(1.2);
  color: #2ee28e;
}

.btn__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 6rem;
  width: 30rem;
  margin-block: 1rem;
}

.btn-card {
  cursor: pointer;
  background-color: #121312;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 700;
  color: #2ee28e;
  border-radius: 1rem;
  border: 1px solid #2ee28e;
  transition: 0.5s;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 8rem;
}
.btn-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 1rem #2ee28e, 0 0 1.5rem #73d6a8;
}
.btn-card:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}
.btn-card__cardprimary {
  background-color: #2ee28e;
  color: #121312;
  border: none;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 80vh;
  overflow: hidden;
}
.hero__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  color: white;
  position: relative;
  min-width: 60%;
}
@media only screen and (max-width: 56.25em) {
  .hero__header {
    min-width: 90%;
  }
}
@media only screen and (max-width: 37.5em) {
  .hero__header {
    min-width: 95%;
  }
}
.hero__header h5 {
  margin-bottom: 0.4rem;
  font-size: 1.6rem;
  font-weight: 300;
}
@media only screen and (max-width: 37.5em) {
  .hero__header h5 {
    font-size: 1rem;
  }
}
.hero__header h4 {
  font-size: 1.6rem;
}
.hero__header h1 {
  font-size: 3.5rem;
  margin-block: 0.5rem 0.5rem;
}
.hero__social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  left: 0;
  bottom: 1rem;
  gap: 0.8rem;
  background: transparent;
}
@media only screen and (max-width: 37.5em) {
  .hero__social {
    left: 0rem;
    bottom: 6.5rem;
    gap: 0.3rem;
  }
}
.hero__social a {
  background: transparent;
  padding: 0.5rem;
  color: rgba(86, 243, 183, 0.4784313725);
  font-size: 3rem;
}
.hero__social a:hover {
  color: #2ee28e;
}
@media only screen and (max-width: 37.5em) {
  .hero__social a {
    font-size: 2.2rem;
  }
}
.hero__me {
  background: linear-gradient(#121312, #2ee28e);
  overflow: hidden;
  width: 30rem;
  height: 40rem;
  border-radius: 12rem 12rem 0 0;
  position: relative;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .hero__me {
    width: 25rem;
    height: 35rem;
  }
}
.hero__me img {
  position: absolute;
  left: -2.5rem;
  bottom: 0rem;
  min-width: 100%;
  min-height: 90%;
}
.hero__scrollDown {
  text-decoration: none;
  color: #2ee28e;
  position: absolute;
  right: 0;
  bottom: 7rem;
  transform: rotate(90deg);
  font-size: 2rem;
  font-weight: 300;
}
@media only screen and (max-width: 37.5em) {
  .hero__scrollDown {
    display: none;
  }
}

#about {
  padding-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#about h5 {
  color: rgba(131, 131, 131, 0.699);
}
#about h1 {
  color: #ffffff;
}

@media only screen and (max-width: 37.5em) {
  .about__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 2rem;
  }
}
.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  margin-top: 6rem;
  max-width: 90%;
  min-height: 40rem;
}
@media only screen and (max-width: 56.25em) {
  .about__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  background: linear-gradient(45deg, #2ee28e, transparent);
  border-radius: 5rem;
  display: grid;
  place-items: center;
  max-width: 50rem;
  max-height: 40rem;
}
@media only screen and (max-width: 56.25em) {
  .about__me {
    margin-bottom: 5rem;
    width: 50%;
    height: 50%;
  }
}
@media only screen and (max-width: 37.5em) {
  .about__me {
    margin-bottom: 5rem;
    width: 60%;
    height: 60%;
  }
}
.about__me__img {
  border-radius: 5rem;
  transform: rotate(25deg);
  transition: all 0.2s;
  overflow: hidden;
}
.about__me__img:hover {
  transform: rotate(0);
  box-shadow: 0 0 1rem #2ee28e, 0 0 0.5rem #73d6a8;
}
.about__me__img img {
  border-radius: 5rem;
  width: 100%;
  height: 100%;
}
.about__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  max-width: 53.1rem;
  max-height: 40rem;
}
@media only screen and (max-width: 37.5em) {
  .about__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.about__card {
  cursor: pointer;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  place-items: center;
  background-color: rgba(46, 226, 142, 0.8);
  color: #ffffff;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 1rem;
  padding-block: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .about__card {
    min-width: 15rem;
  }
}
.about__card:hover {
  border: 1px solid #2ee28e;
  background-color: transparent;
}
.about__card p {
  font-size: 2.5rem;
}
@media only screen and (max-width: 75em) {
  .about__card p {
    font-size: 2rem;
  }
}
.about__card small {
  font-size: 1.4rem;
}
@media only screen and (max-width: 75em) {
  .about__card small {
    font-size: 1.2rem;
  }
}
.about__icon {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 75em) {
  .about__icon {
    margin-bottom: 0.7rem;
    font-size: 2.2rem;
  }
}
.about__describe {
  max-width: 53.1rem;
  margin: 2rem 0 2.7rem;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
@media only screen and (max-width: 37.5em) {
  .about__describe {
    margin-top: 3rem;
    text-align: center;
  }
}

.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  place-items: center;
  padding-block: 5rem;
  max-width: 90%;
  min-height: 40rem;
}
@media only screen and (max-width: 75em) {
  .experience__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 56.25em) {
  .experience__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.experience__frontend, .experience__backend {
  background-color: transparent;
  border: 2px solid #2ee28e;
  padding: 2.4rem 5rem;
  border-radius: 4rem;
  min-height: 30rem;
  min-width: 50rem;
}
@media only screen and (max-width: 56.25em) {
  .experience__frontend, .experience__backend {
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .experience__frontend, .experience__backend {
    border: none;
    padding: 0;
    display: grid;
    place-items: center;
  }
}
.experience__frontend h3, .experience__backend h3 {
  text-align: center;
  font-size: 1.8rem;
  letter-spacing: 0.3rem;
  padding-bottom: 2rem;
  color: #2ee28e;
}
.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 37.5em) {
  .experience__content {
    grid-template-columns: 1fr;
    max-width: 90%;
  }
}
.experience__details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}
@media only screen and (max-width: 56.25em) {
  .experience__details {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.experience__details h4 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.experience__details small {
  font-size: 1.2rem;
}
.experience__icon {
  font-size: 2rem;
  color: #2ee28e;
}

#experience {
  padding-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#experience h5 {
  color: rgba(131, 131, 131, 0.699);
}
#experience h1 {
  color: #ffffff;
}

#projects {
  display: grid;
  place-items: center;
  padding-bottom: 10rem;
}

.projects__subtitle {
  color: rgba(131, 131, 131, 0.699);
}
.projects__filters {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  column-gap: 0.8rem;
}
.projects__item {
  cursor: pointer;
  padding: 0.2rem 0.8rem;
  font-size: 2rem;
}
.projects__container {
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 100rem;
}
@media only screen and (max-width: 56.25em) {
  .projects__container {
    gap: 2rem;
    max-width: 100%;
  }
}
.projects__card {
  background-color: rgba(34, 33, 33, 0.5568627451);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.3rem #ffffff, 0 0 0.5rem #121312;
}
.projects__img {
  min-width: 35rem;
  min-height: 20rem;
  max-width: 35rem;
  max-height: 20rem;
  border-radius: 1rem;
  margin-top: 0.4rem;
  margin-bottom: 0.7rem;
}
@media only screen and (max-width: 56.25em) {
  .projects__img {
    min-width: 25rem;
    min-height: 15rem;
    max-width: 25rem;
    max-height: 15rem;
  }
}
.projects__title {
  font-size: 2rem;
}
.projects__buttons {
  padding-block: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;
}

.active {
  border-radius: 1rem;
  border: 2px solid #2ee28e;
}

.contact__subtitle {
  color: rgba(131, 131, 131, 0.699);
}
.contact__container {
  margin-top: 3rem;
  display: grid;
  place-items: center;
  grid-template-columns: 35% 60%;
  gap: 1%;
  width: 90%;
  max-width: 116rem;
}
@media only screen and (max-width: 75em) {
  .contact__container {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
.contact__content {
  display: grid;
  place-items: center;
  margin-top: 2rem;
  width: 90%;
  height: 100%;
}
@media only screen and (max-width: 56.25em) {
  .contact__content {
    width: 100%;
    gap: 1rem;
  }
}
.contact__info {
  display: grid;
  place-items: flex-end;
  width: 100%;
}
@media only screen and (max-width: 75em) {
  .contact__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-width: 90%;
  }
}
@media only screen and (max-width: 37.5em) {
  .contact__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
}
.contact__card {
  margin-top: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  border: 1px solid #2ee28e;
  border-radius: 1rem;
  padding-block: 2rem;
  padding-inline: 2rem;
  background-color: rgba(46, 226, 142, 0.699);
  width: 65%;
}
@media only screen and (max-width: 75em) {
  .contact__card {
    width: 30%;
  }
}
@media only screen and (max-width: 56.25em) {
  .contact__card {
    width: 30%;
    max-width: 18rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .contact__card {
    width: 100%;
  }
}
.contact__card:hover {
  border: 1px solid #2ee28e;
  background-color: transparent;
}
.contact__card__title {
  font-size: 2.5rem;
  padding-block: 0.4rem;
}
@media only screen and (max-width: 56.25em) {
  .contact__card__title {
    font-size: 2rem;
  }
}
.contact__card__data {
  font-size: 1.4rem;
}
@media only screen and (max-width: 56.25em) {
  .contact__card__data {
    font-size: 1.2rem;
  }
}
.contact__icon {
  font-size: 4rem;
  color: #ffffff;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 56.25em) {
  .contact__icon {
    font-size: 3rem;
  }
}
.contact__form {
  display: grid;
  place-items: start;
  gap: 1.2rem;
  width: 100%;
  padding-block: 5rem;
  max-width: 70rem;
}
@media only screen and (max-width: 75em) {
  .contact__form {
    place-items: center;
    width: 80%;
  }
}
.contact__form__input {
  width: 100%;
  border-radius: 1rem;
  background-color: transparent;
  border: 2px solid #2ee28e;
  height: 7rem;
  padding-left: 2rem;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}
.contact__form__input::placeholder {
  color: #2ee28e;
}
.contact__form__input:focus {
  padding-left: 2rem;
  background-color: transparent;
  border: none;
  outline: 2px solid #2ee28e;
}
.contact__form__textarea {
  width: 100%;
  border-radius: 1rem;
  background-color: transparent;
  border: 2px solid #2ee28e;
  padding: 2rem 0 0 2rem;
  font-family: "Poppins", sans-serif;
  resize: none;
  color: #ffffff;
  height: 25rem;
}
.contact__form__textarea::placeholder {
  color: #2ee28e;
}
.contact__form__textarea:focus {
  padding-left: 2rem;
  background-color: transparent;
  border: none;
  outline: 2px solid #2ee28e;
}

#contact {
  display: grid;
  place-items: center;
  padding-bottom: 5rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30rem;
  width: 100%;
  padding-block: 5rem 8rem;
}
.footer__content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  color: #ffffff;
}
@media only screen and (max-width: 75em) {
  .footer__content {
    padding-top: 1.5rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .footer__content {
    display: none;
  }
}
.footer__content > .footer__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  max-width: 30%;
}
@media only screen and (max-width: 75em) {
  .footer__content > .footer__section {
    justify-content: center;
  }
}
.footer__content > .footer__section h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.footer__content > .footer__section p {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
@media only screen and (max-width: 75em) {
  .footer__content > .footer__section p {
    text-align: start;
  }
}
.footer__content > .footer__section ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}
.footer__content > .footer__section ul li {
  margin-bottom: 5px;
}
.footer__content > .footer__section ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
}
.footer__content > .footer__section ul li a:hover {
  text-decoration: underline;
}
.footer__content__bottom {
  margin-top: 2rem;
  text-align: center;
  font-size: 12px;
}
.footer__logo {
  margin: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 75em) {
  .footer__logo {
    flex-direction: column;
  }
}
.footer__logo img {
  width: 20rem;
  height: 20rem;
}
@media only screen and (min-width: 112.5em) {
  .footer__logo img {
    width: 20rem;
    height: 20rem;
  }
}
@media only screen and (max-width: 75em) {
  .footer__logo img {
    width: 13rem;
    height: 13rem;
  }
}
.footer__nav {
  margin-top: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 90%;
}
@media only screen and (min-width: 112.5em) {
  .footer__nav {
    min-width: 80%;
  }
}
@media only screen and (max-width: 56.25em) {
  .footer__nav {
    margin-top: 5rem;
    flex-direction: column;
  }
}
.footer__list {
  border-top: 1px solid #ffffff;
  padding-top: 2rem;
  padding-inline: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
@media only screen and (max-width: 75em) {
  .footer__list {
    padding-top: 1rem;
    padding-inline: 1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .footer__list {
    width: 100%;
    padding-bottom: 2rem;
  }
}
.footer__item {
  list-style: none;
  margin-left: 2rem;
  padding: 2rem;
  padding-top: 1rem;
}
@media only screen and (max-width: 75em) {
  .footer__item {
    padding-top: 1rem;
    padding-inline: 1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .footer__item {
    margin-left: 0rem;
    padding: 0.5rem;
    padding-top: 1rem;
  }
}
.footer__item a {
  font-size: 1.6rem;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  text-transform: initial;
  letter-spacing: 2px;
  color: rgb(232, 216, 252);
}
.footer__copyright {
  text-align: center;
  list-style: none;
  margin-left: 2rem;
  font-size: 2rem;
  font-weight: 400;
  color: #ffffff;
  text-transform: initial;
  letter-spacing: 2px;
  color: rgb(232, 216, 252);
  border-top: 1px solid #ffffff;
  padding-top: 2rem;
  padding-block: 2rem;
}
@media only screen and (max-width: 56.25em) {
  .footer__copyright {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 75em) {
  .footer__copyright {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .footer__copyright {
    font-size: 1rem;
  }
}
.footer__link:link, .footer__link:visited {
  font-size: 2rem;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  text-transform: initial;
  display: inline-block;
  letter-spacing: 2px;
  color: rgb(232, 216, 252);
  transition: all 0.2s;
}
@media only screen and (max-width: 56.25em) {
  .footer__link:link, .footer__link:visited {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 75em) {
  .footer__link:link, .footer__link:visited {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .footer__link:link, .footer__link:visited {
    font-size: 1.2rem;
  }
}
.footer__link:hover, .footer__link:active {
  color: #ffffff;
  box-shadow: 0 1rem 2rem rgba(115, 214, 168, 0.9);
  transform: rotate(5deg) scale(1.3);
}


