.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 30rem;
    width: 100%;
    padding-block: 5rem 8rem;

      
        &__content {
          display: flex;
          justify-content: space-around;
          align-items: center;
          max-width: 1200px;
          margin: 0 auto;
          color: $color-white;


          
        @include respond(tab-land){
            padding-top: 1.5rem;
        }
        @include respond(tab-port){
            display: none;
        }
        @include respond(tab-phone){
            display: none;
        }
      
          & > .footer__section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
            max-width: 30%;

            @include respond(tab-land){
                justify-content: center;
            }
       
      
            h4 {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 10px;
            }
      
            p {
              font-size: 14px;
              margin-bottom: 10px;
              text-align: center;
              @include respond(tab-land){
                text-align: start;
              
               }
         
           
            }
      
            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              list-style: none;
              padding: 0;

              @include respond(tab-land){
            }
         
      
              li {
                margin-bottom: 5px;
      
                a {
                  text-decoration: none;
                  color: $color-white;
                  font-size: 14px;
      
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
      
      
        &__bottom {
          margin-top: 2rem;
          text-align: center;
          font-size: 12px;
        }
        }
    &__logo {
        margin: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @include respond(tab-land){
            flex-direction: column;
        }
       
    }

    &__logo img {
        width: 20rem;
        height:20rem;

        @include respond(big-desktop) {
            width: 20rem;
            height: 20rem;
        }

        @include respond(tab-land) {
            width: 13rem;
            height: 13rem;
        }

    }

    &__nav {
        margin-top: 10rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 90%;

        @include respond(big-desktop) {
            min-width: 80%;
        }

        @include respond(tab-port) {
            margin-top: 5rem;
            flex-direction: column;
        }

    }

    &__list {
        border-top: 1px solid #ffffff;
        padding-top: 2rem;
        padding-inline: 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        @include respond(tab-land) {
            padding-top: 1rem;
            padding-inline: 1rem;
        }
        @include respond(phone) {
          width: 100%;
          padding-bottom: 2rem;
        }

    }

    &__item {

        list-style: none;
        margin-left: 2rem;
        padding: 2rem;
        padding-top: 1rem;

        @include respond(tab-land) {
            padding-top: 1rem;
            padding-inline: 1rem;
        }

        @include respond(phone) {
            margin-left: 0rem;
            padding: 0.5rem;
            padding-top: 1rem;
        }



    }

    &__item a {
        font-size: 1.6rem;
        font-weight: 400;
        color: $color-white;
        text-decoration: none;
        text-transform: initial;
        letter-spacing: 2px;
        color: rgb(232, 216, 252);

     

    }

    &__copyright {
        text-align: center;
        list-style: none;
        margin-left: 2rem;
        font-size: 2rem;
        font-weight: 400;
        color: $color-white;
        text-transform: initial;
        letter-spacing: 2px;
        color: rgb(232, 216, 252);
        border-top: 1px solid #ffffff;
        padding-top: 2rem;
        padding-block: 2rem;

        @include respond(tab-port) {
            font-size: 1.5rem;
            margin-top: 2rem;
        }
        @include respond(tab-land) {
            font-size: 1.8rem;
        }

        @include respond(phone) {
            font-size: 1rem;
        }

    }

    &__link {

        &:link,
        &:visited {
            font-size: 2rem;
            font-weight: 400;
            color: $color-white;
            text-decoration: none;
            text-transform: initial;
            display: inline-block;
            letter-spacing: 2px;
            color: rgb(232, 216, 252);
            transition: all 0.2s;

            @include respond(tab-port) {

                font-size: 1.4rem;
            }


            @include respond(tab-land) {
                font-size: 1.8rem;
            }
    
            @include respond(phone) {
                font-size: 1.2rem;
            }
    
    
        }

        &:hover,
        &:active {
            color: $color-white;
            box-shadow: 0 1rem 2rem rgba($color-second, .9);
            transform: rotate(5deg) scale(1.3);
        }
    }
}