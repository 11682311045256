.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 80vh;
    overflow: hidden;
    


    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        color: white;
        position: relative;
        min-width: 60%;

        @include respond(tab-port){
            min-width: 90%;
        }
        @include respond(phone){
            min-width: 95%;
        }
    }

    &__header h5 {
        margin-bottom: 0.4rem;
     
        font-size: 1.6rem;
        font-weight: 300;

        @include respond(phone){
            font-size: 1rem;
        }
    }

    &__header h4 {
        font-size: 1.6rem;
    }

    &__header h1 {
        font-size: 3.5rem;
        margin-block: 0.5rem 0.5rem;
    }


    &__social {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        left: 0;
        bottom: 1rem;
        gap: 0.8rem;
        background: transparent;

        @include respond(phone){
            left: 0rem;
            bottom: 6.5rem;
            gap: 0.3rem;
         }
    }

    &__social a {
        background: transparent;
        padding: .5rem;
        color: #56f3b77a;
        font-size: 3rem;

        &:hover{
           color: $color-first; 
        }
        @include respond(phone){
            font-size: 2.2rem;
         }
      
    }

    &__me{
        background:linear-gradient($color-black,$color-first);
        overflow: hidden;
        width:30rem;
        height: 40rem;
        border-radius: 12rem  12rem 0 0;
        position: relative;
        margin-bottom: 2rem;

        @include respond(phone){
            width:25rem;
        height: 35rem;
        }

    }

    &__me img{
        position: absolute;
        left: -2.5rem;
        bottom: 0rem;
        min-width: 100%;
        min-height: 90%;
    }

    &__scrollDown{
        text-decoration: none;
        color: $color-first;
        position: absolute;
        right: 0;
        bottom: 7rem;
        transform: rotate(90deg);
        font-size: 2rem;
        font-weight: 300;

        @include respond(phone){
            display: none;
        }
    }
}