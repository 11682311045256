@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;1,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;800&family=Roboto:ital,wght@0,300;0,500;1,500;1,900&display=swap');


// COLORS 

$color-first: #2ee28e;
$color-second: #73d6a8;
$color-white: #ffffff;
$color-bg: #2221218e;
$color-black: #121312;


// FONTS 
$font-family-one: "Roboto", sans-serif;
$font-family-two: "Red Hat Display", sans-serif;
$font-family-three:'Poppins', sans-serif;
