.contact {


    &__subtitle {
        color: rgba(131, 131, 131, 0.699);
    }

    &__container {
        margin-top: 3rem;
        display: grid;
        place-items: center;
        grid-template-columns: 35% 60%;
        gap: 1%;
        width: 90%;
        max-width: 116rem;

        @include respond(tab-land){
            grid-template-columns: 1fr;
            width: 100%;
        }

    }

  

    &__content {
        display: grid;
        place-items: center;
        margin-top: 2rem;
        width: 90%;
        height: 100%;

        @include respond(tab-port){
         
            width: 100%;
            gap: 1rem;
        }
    
       
    }

    &__info {
        display: grid;
        place-items: flex-end;
        width: 100%;

        @include respond(tab-land){
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            min-width: 90%;
        }

        @include respond(phone){
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 1rem;
        }
    }


    &__card {
        margin-top: 2rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $color-white;
        text-align: center;
        border: 1px solid $color-first;
        border-radius: 1rem;
        padding-block: 2rem;
        padding-inline: 2rem;
        background-color: rgba($color-first, 0.699);
        width: 65%;

        @include respond(tab-land){
            width: 30%
        }


        @include respond(tab-port){
         
            width: 30%;
            max-width:18rem;

        }
        @include respond(phone){
         
            width: 100%;

        }
       


        &:hover {
            border: 1px solid $color-first;
            background-color: transparent;
        }

        &__title {
            font-size: 2.5rem;
            padding-block: 0.4rem;

            @include respond(tab-port){
                font-size: 2rem;
            }
        }

        &__data {
            font-size: 1.4rem;

            @include respond(tab-port){
                font-size: 1.2rem;
            }
        }



    }

    &__icon {
        font-size: 4rem;
        color: $color-white;
        margin-bottom: 1rem;

        @include respond(tab-port){
            font-size: 3rem;
        }
    }


    &__form {
        display: grid;
        place-items: start;
        gap: 1.2rem;
        width: 100%;
        padding-block: 5rem;
        max-width: 70rem;

        @include respond(tab-land){
          place-items: center;
          width: 80%;
        }

        &__input {
            width: 100%;
            border-radius: 1rem;
            background-color: transparent;
            border: 2px solid $color-first;
            height: 7rem;
            padding-left: 2rem;
            color: $color-white;
            font-family: $font-family-three;

            &::placeholder {
                color: $color-first;
                
            }

            &:focus {
                padding-left: 2rem;
                background-color: transparent;
                border: none;
                outline: 2px solid $color-first;
            }
        }

        &__textarea{
            width: 100%;
            border-radius: 1rem;
            background-color: transparent;
            border: 2px solid $color-first;
            padding: 2rem 0 0 2rem;
            font-family: $font-family-three;
            resize: none;
            color: $color-white;
            height: 25rem;

            &::placeholder {
                color: $color-first;
                
            }

            &:focus {
                padding-left: 2rem;
                background-color: transparent;
                border: none;
                outline: 2px solid $color-first;
            }
        }


    }


}

#contact {
    display: grid;
    place-items: center;
    padding-bottom: 5rem;
}